//
// _form-control.scss
//
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
  }
  

.form-icon{
    position: relative;
    .form-control-icon {
        padding-left: $input-padding-x*3;
        position: relative;
    }
    .search-input {
        font-family: 'Causten';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        border-radius: 8px;
        @include placeholder {
              color: #70798D;
          }
    }
    i {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 18px;
        display: flex;
        align-items: center;
        color:#96A1BA;
    }

    &.right{
        .form-control-icon {
            padding-right: $input-padding-x*3;
            padding-left: $input-padding-x;
            position: relative;
        }
        i {
            left: auto;
            right: 18px;
        }
    }
}

[data-layout-mode="dark"]{
    .form-select {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-400}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
    }
}

.form-group{
    .label {
        font-family: 'Causten';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #7D8898;
    }
    .form-select {
        border-radius: 8px !important;
    }
}