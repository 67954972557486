.mdi {
    color: var(--#{$variable-prefix}gray-500);


        font-size: 24px;
        margin-right: 10px;
        color: var(--#{$variable-prefix}gray-600);
        transition: all 0.4s;
        vertical-align: middle;

    &svg {
        margin-right: 10px;
        transition: all 0.4s;
        height: 20px;
    }

    &:hover {
            color: $primary;
            transform: scale(1.5);
    }

}