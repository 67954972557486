.rdt_Table {
  overflow: auto;
  border: 1px solid #e5eaed;
  box-sizing: border-box;
  border-radius: 8px;
}

.rdt_TableCol_Sortable {
  min-width: 80;

  font-family: 'Causten';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.rdt_TableCell {
  font-family: 'Causten';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #7d8898;
}
.rdt_TableHead {
  .rdt_TableHeadRow {
    .rdt_TableCol {
      .rdt_TableCol_Sortable {
        font-size: 13px !important;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}
.CustomTable {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        .rdt_TableCol_Sortable {
          font-size: 12px !important;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}
