.sliding-modal-sm {
  position: fixed;
  bottom: 0vh;
  background-color: #fff;
  height: 100%;
  width: 30%;
  top: 70px;
  right: -150vw;
  padding: 0 12px 12px;
  transition: right 0.3s ease-out;
  z-index: 100;
  overflow: scroll;
  &.show {
    right: 0;
  }
}
.sliding-modal-md {
  position: fixed;
  bottom: 0vh;
  background-color: #fff;
  height: 100%;
  width: 40%;
  top: 70px;
  right: -150vw;
  padding: 0 12px 12px;
  transition: right 0.3s ease-out;
  z-index: 100;
  overflow: scroll;
  &.show {
    right: 0;
  }
}
.sliding-modal-lg {
  position: fixed;
  bottom: 0vh;
  background-color: #fff;
  height: 100%;
  width: 50%;
  top: 70px;
  right: -150vw;
  padding: 0 12px 12px;
  transition: right 0.3s ease-out;
  z-index: 100;
  overflow: scroll;
  &.show {
    right: 0;
  }
}
.sliding-modal-xl {
  position: fixed;
  bottom: 0vh;
  background-color: #fff;
  height: 100%;
  width: 70%;
  top: 70px;
  right: -150vw;
  padding: 0 12px 12px;
  transition: right 0.3s ease-out;
  z-index: 100;
  overflow: scroll;
  &.show {
    right: 0;
  }
}

.sliding-overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 50;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.show {
    display: block;
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .sliding-modal-sm {
    width: 100% !important;
    top: 70px;
  }
  .sliding-modal-md {
    width: 100% !important;
    top: 70px;
  }
  .sliding-modal-lg {
    width: 100% !important;
    top: 70px;
  }
  .sliding-modal-xl {
    width: 100% !important;
    top: 70px;
  }
}
